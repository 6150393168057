import avatar1 from '@/assets/images/avatar/1.png';
import avatar2 from '@/assets/images/avatar/2.png';
import avatar3 from '@/assets/images/avatar/3.png';
import avatar4 from '@/assets/images/avatar/4.png';
import avatar5 from '@/assets/images/avatar/5.png';
import avatar6 from '@/assets/images/avatar/6.png';
import avatar7 from '@/assets/images/avatar/7.png';
import avatar8 from '@/assets/images/avatar/8.png';
import avatar9 from '@/assets/images/avatar/9.png';
import avatar10 from '@/assets/images/avatar/10.png';
import avatar11 from '@/assets/images/avatar/11.png';
import avatar12 from '@/assets/images/avatar/12.png';
import avatar13 from '@/assets/images/avatar/13.png';
import avatar14 from '@/assets/images/avatar/14.png';
import avatar15 from '@/assets/images/avatar/15.png';
import avatar16 from '@/assets/images/avatar/16.png';
import avatar17 from '@/assets/images/avatar/17.png';
import avatar18 from '@/assets/images/avatar/18.png';
import avatar19 from '@/assets/images/avatar/19.png';
import avatar20 from '@/assets/images/avatar/20.png';
import avatar21 from '@/assets/images/avatar/21.png';
import avatar22 from '@/assets/images/avatar/22.png';
import avatar23 from '@/assets/images/avatar/23.png';
import avatar24 from '@/assets/images/avatar/24.png';
import avatar25 from '@/assets/images/avatar/25.png';

export const listAvatar = [
  avatar1,
  avatar2,
  avatar3,
  avatar4,
  avatar5,
  avatar6,
  avatar7,
  avatar8,
  avatar9,
  avatar10,
  avatar11,
  avatar12,
  avatar13,
  avatar14,
  avatar15,
  avatar16,
  avatar17,
  avatar18,
  avatar19,
  avatar20,
  avatar21,
  avatar22,
  avatar23,
  avatar24,
  avatar25,
];
