import rank1 from '@/assets/images/Rank-1.png';
import rank2 from '@/assets/images/Rank-2.png';
import rank3 from '@/assets/images/Rank-3.png';
import rank4 from '@/assets/images/Rank-4.png';
import rank5 from '@/assets/images/Rank-5.png';

export const exchangeRanking = [rank1, rank2, rank3, rank4, rank5];

export const filterOptionKey = {
  LATEST: 'latest',
  EXPERT_SCORE: 'expert_score',
  USER_SCORE: 'user_score',
  EXPERT_ASC: 'expert_score_asc',
  EXPERT_DESC: 'expert_score_desc',
  USER_ASC: 'user_score_asc',
  USER_DESC: 'user_score_desc',
};

export const filterScoreOption = {
  EXPERT_SCORE: 'expert_score',
  USER_SCORE: 'user_score',
  ORDER_BY: 'order_by',
};

export const queryOption = {
  EXPERT_SCORE: 'expert-score',
  USER_SCORE: 'user-score',
  ORDER_BY: 'order-by',
};

export const ScoreOrderOption = {
  ASC: '_asc',
  DESC: '_desc',
};

export const filterOptionRatingKey = {
  LATEST: 'latest',
  OLDEST: 'oldest',
  HIGHEST_SCORE: 'highest-score',
  LOWEST_SCORE: 'lowest-score',
};

export const filterOptions = [
  {
    value: filterOptionKey.LATEST,
    title: 'Mới nhất',
  },
  {
    value: filterOptionKey.EXPERT_SCORE,
    title: 'Điểm chuyên gia cao nhất',
  },
  {
    value: filterOptionKey.USER_SCORE,
    title: 'Điểm nhà đầu tư cao nhất',
  },
];

export const rangeScoreOptionKey = {
  LESS_THAN_5: 'less-than-5',
  'FROM_5_TO_7.5': 'from-5-to-7.5',
  'OVER_7.5': 'over-7.5',
  ALL: 'all',
};

export const rangeScoreOptions = [
  {
    value: rangeScoreOptionKey.ALL,
    title: '-- Toàn bộ --',
  },
  {
    value: rangeScoreOptionKey.LESS_THAN_5,
    title: 'Từ 5 trở xuống',
  },
  {
    value: rangeScoreOptionKey['FROM_5_TO_7.5'],
    title: 'Từ 5 tới 7.5',
  },
  {
    value: rangeScoreOptionKey['OVER_7.5'],
    title: 'Từ 7.5 trở lên',
  },
];

export const EXCHANGE_LIMIT = 10;

export const filterOptionsRating = [
  {
    value: filterOptionRatingKey.LATEST,
    title: 'Mới nhất',
  },
  {
    value: filterOptionRatingKey.HIGHEST_SCORE,
    title: 'Điểm cao nhất',
  },
  {
    value: filterOptionRatingKey.LOWEST_SCORE,
    title: 'Điểm thấp nhất',
  },
];

export const formatLeverageExchange = (data: string) => {
  return data.replace(/\//g, ':');
};
