import { EXCHANGE_LIMIT } from '@/constants/exchange';
import { listAvatar } from '@/constants/listAvatar';
import dayjs from 'dayjs';

export const phoneRegExp =
  /^(0|\+84)(\s?)(3[2-9]|5[6|8|9]|7[0|6-9]|8[0-6|8|9]|9[0-4|6-9])(\d)(\s?\d{3})(\s?\d{3})$/;

export const getOffsetFromPage = (page: number) => (page - 1) * EXCHANGE_LIMIT;

export const getPageCount = (
  count: number | null | undefined,
  limit: number,
) => {
  if (!count) return 0;
  const pageCount = Math.ceil(count / limit);
  return !Number.isNaN(pageCount) ? pageCount : 0;
};

export const copyTextToClipboard = async (text: string) => {
  if ('clipboard' in navigator) {
    return await navigator.clipboard.writeText(text);
  } else {
    return document.execCommand('copy', true, text);
  }
};

export const getCurrentDateRoundedHour = () => {
  const currentTimeInMilliseconds = Date.now(); // Lấy thời gian hiện tại (tính bằng mili giây)
  const roundedTimeInMilliseconds =
    Math.round(currentTimeInMilliseconds / (60 * 60 * 1000)) * (60 * 60 * 1000); // Làm tròn đến giờ gần nhất

  return dayjs(roundedTimeInMilliseconds).format('YYYY-MM-DDTHH:mm:ssZ');
};

export const getRandomAvatar = () => {
  const index = Math.floor(Math.random() * 25);

  return listAvatar[index];
};
export const getTitleMagazineByIndex = (index: number) => {
  if (index < 10) return 'No.0' + index;
  return 'No.' + index;
};
